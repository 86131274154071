import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_IT } from "./it/itTranslation";
import { TRANSLATIONS_EN } from "./uk/ukTranslation";
import { TRANSLATIONS_JP } from "./jp/jpTranslation";
import { TRANSLATIONS_FR } from "./fr/frTranslation";
import { TRANSLATIONS_DE } from "./de/deTranslation";
import { TRANSLATIONS_NE } from "./ne/neTranslation";
import { TRANSLATIONS_ESP } from "./esp/espTranslation";
import { TRANSLATIONS_SVE } from "./sve/sveTranslation";

i18n.use(initReactI18next).init({
  resources: {
    it: {
      translation: TRANSLATIONS_IT,
    },
    en: {
      translation: TRANSLATIONS_EN,
    },
    jp: {
      translation: TRANSLATIONS_JP,
    },
    fr: {
      translation: TRANSLATIONS_FR,
    },
    de: {
      translation: TRANSLATIONS_DE,
    },
    nl: {
      translation: TRANSLATIONS_NE,
    },
    esp: {
      translation: TRANSLATIONS_ESP,
    },
    sve: {
      translation: TRANSLATIONS_SVE,
    },
  },
});

i18n.changeLanguage("it");
