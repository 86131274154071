class WmSessionInfo {
  /**
   *
   * @param {string} token
   * @param {string} username
   * @param {string} apiurl
   * @param {string} bloburl
   */
  constructor(token, username, apiurl, bloburl, redirect, installerID, started, lastRefresh) {
    if (typeof token != "string") {
      throw new TypeError("invalid token");
    }
    if (typeof username != "string") {
      throw new TypeError("invalid username");
    }
    if (typeof apiurl != "string") {
      throw new TypeError("invalid apiurl");
    }
    if (typeof bloburl != "string") {
      throw new TypeError("invalid bloburl");
    }
    if (typeof redirect != "string") {
      throw new TypeError("invalid redirect");
    }
    if (typeof installerID != "string") {
      throw new TypeError("invalid installerID");
    }

    this.Token = token;
    this.Username = username;
    this.ApiEndpoint = apiurl;
    this.BlobEndpoint = bloburl;
    this.RedirectTo = redirect;
    this.InstallerID = installerID;
    this.Started = started;
    this.LastRefresh = lastRefresh;
  }
}

class WmRegReqResult {
  constructor(number, success) {
    if (typeof token != "number") {
      throw new TypeError("invalid number");
    }
    if (typeof token != "boolean") {
      throw new TypeError("invalid success");
    }
    this.Number = number;
    this.Success = success;
  }
}

export class WmCloud {
  /** @public @static @class */
  static SessionInfo = WmSessionInfo;
  /** @public @static @class */
  static RegReqResult = WmRegReqResult;

  /**
   * @param {string} loginurl
   * @param {string} registerurl
   * @returns
   */
  constructor(loginurl, registerurl) {
    if (loginurl == undefined) {
      loginurl = "/api/login";
    }
    if (registerurl == undefined) {
      registerurl = "/api/register";
    }
    if (typeof loginurl != "string") {
      return new TypeError("invalid login url");
    }
    if (typeof registerurl != "string") {
      return new TypeError("invalid register url");
    }

    this.loginURL = loginurl;
    this.registerURL = registerurl;
  }

  /**
   * controlla se siamo gia autenticati e nel caso effettua un redirect o ritorna i dati della sessione
   * @param {boolean} autoredirect
   * @returns {WmSessionInfo|Error}
   */
  async Session(autoredirect) {
    if (typeof autoredirect != "boolean") {
      return new TypeError("invalid autoredirect");
    }

    const response = await fetch(this.loginURL, { method: "GET" });
    if (response.status != 200) {
      return null;
    }

    const result = WmCloud.Cast(await response.json(), WmCloud.SessionInfo.prototype);
    if (autoredirect) {
      window.location = result.RedirectTo;
    }
    return result;
  }

  /**
   * Effettua una login con i dati forniti
   * @param {string} username
   * @param {string} password
   * @param {boolean} autoredirect
   * @returns  {WmSessionInfo|Error}
   */
  async Login(username, password, autoredirect) {
    if (typeof username != "string") {
      return new TypeError("invalid username");
    }
    if (typeof password != "string") {
      return new TypeError("invalid password");
    }
    if (typeof autoredirect != "boolean") {
      return new TypeError("invalid autoredirect");
    }

    const response = await fetch(this.loginURL, {
      method: "POST",
      body: WmCloud.FormData({ username: username, password: password }),
    });
    if (response.status != 200) {
      return new Error("login failed");
    }

    const result = WmCloud.Cast(await response.json(), WmCloud.SessionInfo.prototype);
    if (autoredirect) {
      window.location = result.RedirectTo;
    }
    return result;
  }

  async Register(email, phone, company, name, surname, piva, notes) {
    if (!WmCloud.IsNonEmptyString(email)) {
      throw new TypeError("invalid email");
    }
    if (!WmCloud.IsNonEmptyString(phone)) {
      throw new TypeError("invalid phone");
    }
    if (!WmCloud.IsNonEmptyString(company)) {
      throw new TypeError("invalid company");
    }
    if (!WmCloud.IsNonEmptyString(name)) {
      throw new TypeError("invalid name");
    }
    if (!WmCloud.IsNonEmptyString(surname)) {
      throw new TypeError("invalid surname");
    }
    if (typeof piva != "string") {
      return new TypeError("invalid piva");
    }
    if (typeof notes != "string") {
      return new TypeError("invalid notes");
    }

    const response = await fetch(this.registerURL, {
      method: "POST",
      body: WmCloud.FormData({
        email: email,
        phone: phone,
        company: company,
        name: name,
        surname: surname,
        piva: piva,
        notes: notes,
      }),
    });
    if (response.status != 200) {
      return new Error("registration failed");
    }
    const result = WmCloud.Cast(await response.json(), WmCloud.RegReqResult.prototype);
    return result;
  }

  /**
   * Cast a plain objects (obtanied by a json deserialization) to the specified type
   * @param {object} array
   * @param {prototype} prototype
   * @returns {object?}
   */
  static Cast(object, prototype) {
    if (object == null) {
      return null;
    }
    return Object.create(prototype, Object.getOwnPropertyDescriptors(object));
  }
  /**
   * Crea un FormData a partire da un oggetto chiave valore
   * @param {Map<String, String>} obj
   * @returns {FormData}
   */
  static FormData(obj) {
    const data = new FormData();
    for (let key in obj) {
      data.set(key, obj[key]);
    }
    return data;
  }
  /**
   * Controlla che un valore sia una stringa NON vuota
   * @param {*} value
   * @returns {boolean}
   */
  static IsNonEmptyString(value) {
    return typeof value == "string" && value != "";
  }
}
