//styles

import "./styles.css";
//assets
import WebmuxLogo from "./assets/webmuxNocloud2.png";
import GpsLogo from "./assets/logonegalpharesize.png";
//mui
import Select from "@mui/material/Select";
import { MenuItem, Stack, Modal, Button } from "@mui/material";
//circle-flags
import { CircleFlag } from "react-circle-flags";
//logic
import { Login } from "./scripts/login";
//i18
import i18next, { t } from "i18next";
import "./translations/i18n";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const modal_container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  outline: "none",
  padding: "20px",
  width: "400px", // Imposta la larghezza massima del modal
  maxHeight: "100vh", // Imposta l'altezza massima del modal
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
};

function App() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    let languageBrowser = navigator.language.split("-")[0];

    if (languageBrowser === "it") {
      handleLanguageChange("it");
      setLanguage("it");
    }
    if (languageBrowser === "en") {
      handleLanguageChange("en");
      setLanguage("en");
    }

    if (languageBrowser === "fr") {
      handleLanguageChange("fr");
      setLanguage("fr");
    }

    if (languageBrowser === "de") {
      handleLanguageChange("de");
      setLanguage("de");
    }

    if (languageBrowser === "nl") {
      handleLanguageChange("nl");
      setLanguage("nl");
    }

    if (languageBrowser === "es") {
      handleLanguageChange("esp");
      setLanguage("esp");
    }

    if (languageBrowser === "sv") {
      handleLanguageChange("sve");
      setLanguage("sve");
    }

    if (languageBrowser === "ja") {
      handleLanguageChange("jp");
      setLanguage("jp");
    }

    console.log(`LINGUA: ${languageBrowser}`);
  }, []);

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleLogin(usr, psw, redirect) {
    debugger;
    const res = Login(usr, psw, redirect);

    res.then((data) => {
      console.log(data.RedirectTo);
      if (data instanceof TypeError || data instanceof Error) {
        setIsModalErrorOpen(true);
      } else {
        var urlDestination = data.RedirectTo + "?language=" + encodeURIComponent(language);

        window.location.href = urlDestination;
      }
    });
  }

  function handleLanguageChange(lang) {
    setLanguage(lang);
    i18next.changeLanguage(lang);
  }
  function handleKeyDown(e) {
    debugger;
    if (e.key === "Enter") {
      handleLogin(username, password, false);
    }
  }

  // ...

  return (
    <>
      <div
        className="App"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLogin(username, password, false);
          }
        }}
      >
        <div className="bg">
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={WebmuxLogo} alt="webmux logo" className="logo" />
            <Select
              value={language}
              sx={{ marginBottom: "15px", marginTop: "10px", color: "white" }}
              onChange={(e) => {
                handleLanguageChange(e.target.value);
              }}
            >
              <MenuItem value={"it"} on>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="it" height={30} />
                  <h4>Italiano</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"en"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="uk" height={30} />
                  <h4>English</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"fr"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="fr" height={30} />
                  <h4>Français</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"de"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="de" height={30} />
                  <h4>Deutsch</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"nl"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="nl" height={30} />
                  <h4>Nederlands</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"esp"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="es" height={30} />
                  <h4>Español</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"sve"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="se" height={30} />
                  <h4>Svenska</h4>
                </Stack>
              </MenuItem>
              <MenuItem value={"jp"}>
                <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                  <CircleFlag countryCode="jp" height={30} />
                  <h4>日本語</h4>
                </Stack>
              </MenuItem>
            </Select>

            <main class="form-container">
              <form>
                <div class="form-group">
                  <label class="form-label" for="username">
                    {t("username")}
                  </label>
                  <input id="usr" type="text" onChange={handleUsernameChange} />
                </div>
                <div class="form-group">
                  <label class="form-label" for="password">
                    {t("password")}
                  </label>
                  <input id="psw" type="password" onChange={handlePasswordChange} onKeyDown={handleKeyDown} />
                </div>
                <button
                  id="btn-login"
                  type="button"
                  onClick={() => {
                    handleLogin(username, password, false);
                  }}
                >
                  {t("login")}
                </button>
                <p class="text-register">
                  {t("registerMsg")}
                  <a href={`https://webmux.cloud/register/?lang=${encodeURIComponent(language)}`}>
                    {t("registerMsg2")}
                  </a>
                </p>
              </form>
            </main>
            <img className="gps-logo" src={GpsLogo} alt="Gps Standard Logo" />
          </div>
        </div>
      </div>
      <Modal open={isModalErrorOpen}>
        <div style={modal_container}>
          <h3>{t("modalMsg")}</h3>
          <Button
            variant="contained"
            onClick={() => {
              setIsModalErrorOpen(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(App);
